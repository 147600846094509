import {BehaviorSubject, Observable, retry, Subscription} from "rxjs";
import {IndexerDocumentFormModel} from "./indexer-document-form-model";
import {IndexerDocumentFormFieldModel} from "./indexer-document-form-field-model";
import {WsFieldIdentificationRequestStatus, WsFieldIdentificationValueStatus} from "@fiscalteam/nitro-domain-client";
import {ErrorService} from "@fiscalteam/ngx-nitro-services";
import {IndexerFrontConfigService} from "../../../config/indexer-front-config.service";
import {Injectable} from "@angular/core";

@Injectable()
export class IndexerDocumentFormFieldService {

  private readonly STRUCTURED_PAYMENT_REFERENCE_FIELD_CODE = 'STRUCTURED_PAYMENT_REFERENCE';
  private readonly UNSTRUCTURED_PAYMENT_REFERENCE_FIELD_CODE = 'UNSTRUCTURED_PAYMENT_REFERENCE';

  formModel?: IndexerDocumentFormModel;

  private displayedField$ = new BehaviorSubject<IndexerDocumentFormFieldModel | undefined>(undefined);

  constructor(
    private configService: IndexerFrontConfigService,
    private errorService: ErrorService,
  ) {
  }

  onNewFormLoaded(formModel: IndexerDocumentFormModel) {
    this.formModel = formModel;
    this.displayFirstFieldToIndex();
  }

  switchToNextField() {
    const curField = this.displayedField$.getValue();
    if (curField != null && this.isFieldEditable(curField)) {
      const curFieldIndexed = this.isFieldIndexingRequired(curField) === false;
      if (!curFieldIndexed) {
        // Skipping a required field - we might need to forbid this
        console.warn([`Skipping non indexed field`, curField]);
      }

      // Speical case for STRUCTURED_PAYMENT_REFERENCE_FIELD_CODE: if filled, we skip UNSTRUCTURED_PAYMENT_REFERENCE_FIELD_CODE
      const curFieldCode = curField.fieldModel.field.code;
      const structuredPaymentReferenceField = curFieldCode === this.STRUCTURED_PAYMENT_REFERENCE_FIELD_CODE;
      if (curFieldIndexed && structuredPaymentReferenceField != null && this.formModel != null) {
        const unstructuredFieldModel = this.formModel.fieldModels.find(f => f.fieldModel.field.code === this.UNSTRUCTURED_PAYMENT_REFERENCE_FIELD_CODE);
        if (unstructuredFieldModel) {
          // skip unstructured field by commiting to submit it without any value
          unstructuredFieldModel.pendingSubmitLocalChanges = Object.assign({}, unstructuredFieldModel.pendingSubmitLocalChanges, {
            valueStatus: WsFieldIdentificationValueStatus.Submitted
          });
        }
      }
    }

    this.displayNextField();
  }

  displayField(fieldToDisplay: IndexerDocumentFormFieldModel | undefined) {
    const curField = this.displayedField$.getValue();
    if (curField === fieldToDisplay) {
      return;
    }

    // This code path unsubscribed from syncing the previous field value when switching to the next one.
    // This is disabled for now, we unsubscribe when submitting the form
    // if (curField != null) {
    //   if (curField.indexerValuesSyncSubscription != null) {
    //     const priorSyncSubscription = curField.indexerValuesSyncSubscription;
    //     // Wait for sync to complete when unsubscribing
    //     curField.fieldModel.indexerValueSyncing$.pipe(
    //       debounceTime(500),
    //       filter(s => !s),
    //       take(1),
    //     ).subscribe(a=>{
    //       priorSyncSubscription.unsubscribe();
    //     });
    //   }
    // }

    if (fieldToDisplay) {
      // Reset pending local changes
      fieldToDisplay.pendingSubmitLocalChanges = {};

      const syncEnabled = this.configService.isFeatureEnabled("document.indexingValue.sync");
      // Only subscribe when not done already. Unsubscribe occurs on form submit only
      if (syncEnabled && fieldToDisplay.indexerValuesSyncSubscription == null) {
        const syncSubscription = this.syncValues(fieldToDisplay);
        syncSubscription.add(() => {
          fieldToDisplay.fieldModel.indexerValueErrors$.next([]);
        })
        fieldToDisplay.indexerValuesSyncSubscription = syncSubscription;
      }
    }
    this.displayedField$.next(fieldToDisplay);
  }

  getDisplayedField$(): Observable<IndexerDocumentFormFieldModel | undefined> {
    return this.displayedField$.asObservable();
  }

  isFieldEditable(fieldModel: IndexerDocumentFormFieldModel | undefined): boolean {
    if (fieldModel == null) {
      return true;
    }
    const backendValue = fieldModel.fieldModel.indexerValue$.getValue();
    if (backendValue != null && backendValue.valueStatus !== WsFieldIdentificationValueStatus.Displayed) {
      return false;
    }

    return true;
  }

  isFieldIndexingRequired(model: IndexerDocumentFormFieldModel) {
    const needsIndexerValue = model.fieldModel.isIndexerValueMissingNow();
    const committedToSubmit = model.pendingSubmitLocalChanges && model.pendingSubmitLocalChanges.valueStatus != null
      && model.pendingSubmitLocalChanges.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
    // Final status ignored
    const indexerBackendValue = model.fieldModel.indexerValue$.getValue();
    const valueSubmitted = indexerBackendValue && indexerBackendValue.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
    const requestSubmitted = model.fieldModel.fieldRequest.requestStatus !== WsFieldIdentificationRequestStatus.WaitingForIndexing;
    const hasIndexerBackendValue = indexerBackendValue && indexerBackendValue.identifiedValue && indexerBackendValue.identifiedValue.length > 0;
    if (valueSubmitted || requestSubmitted || hasIndexerBackendValue || committedToSubmit) {
      return false;
    }
    // Optional fields should be considered if no value locally
    if (!model.fieldModel.fieldRequest.valueRequired) {
      // We must ignore field with a value persisted on the backend
      // NITRO-2036: As we use ngModel/ngForm; input components emits 'null' value when initialized; and
      // currently all fields are optional. So optional fields are required until the indexer filled something
      // or pressed some button to indicate it is skipped
      const indexerHasFilledTheField = model.fieldModel.isIndexerFilledNow();
      const fieldHasValue = indexerHasFilledTheField || hasIndexerBackendValue || committedToSubmit;
      return !fieldHasValue;
    }

    return needsIndexerValue;
  }

  isFieldSubmitRequired(model: IndexerDocumentFormFieldModel) {
    const needsIndexerValue = model.fieldModel.isIndexerValueMissingNow();
    const indexerValue = model.fieldModel.indexerValue$.getValue();
    const needsSubmit = indexerValue != null && indexerValue.valueStatus === WsFieldIdentificationValueStatus.Displayed;
    return !needsIndexerValue && needsSubmit;
  }

  private displayFirstFieldToIndex() {
    if (this.formModel && this.formModel.fieldModels.length > 0) {
      const firstFieldRequiringIndexing = this.formModel.fieldModels.find(m => this.isFieldIndexingRequired(m));
      if (firstFieldRequiringIndexing != null) {
        this.displayField(firstFieldRequiringIndexing);
      } else {
        // Find any field not filled
        const firstNonRequiredField = this.formModel.fieldModels.find(f => {
          // TODO: duplicate with logic in form service getOtherIndexedFields
          const indexerBackendValue = f.fieldModel.indexerValue$.getValue();
          const valueSubmitted = indexerBackendValue && indexerBackendValue.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
          const requestSubmitted = f.fieldModel.fieldRequest.requestStatus !== WsFieldIdentificationRequestStatus.WaitingForIndexing;
          const hasIdentifiedValueOnBackend = indexerBackendValue && indexerBackendValue.identifiedValue && indexerBackendValue.identifiedValue.length > 0;
          if (valueSubmitted || requestSubmitted) {
            return false;
          }
          const hasIndexerValue = f.fieldModel.isIndexerFilledNow() || hasIdentifiedValueOnBackend;
          if (hasIndexerValue) {
            return false;
          }
          const committedToSubmit = f.pendingSubmitLocalChanges && f.pendingSubmitLocalChanges.valueStatus && f.pendingSubmitLocalChanges.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
          if (committedToSubmit) {
            return false;
          }

          return f.fieldModel.fieldRequest.valueRequired === false && !f.fieldModel.isIndexerFilledNow();
        });
        if (firstNonRequiredField) {
          this.displayField(firstNonRequiredField);
        } else {
          this.displayField(undefined);
        }
      }
    }
  }

  private displayNextField() {
    if (this.formModel == null) {
      this.displayField(undefined);
      return;
    }
    const curField = this.displayedField$.getValue();
    if (curField) {
      const fieldToIndex = this.formModel.fieldModels
        .filter(m => m === curField || m.fieldModel.isIndexerFilledNow() === false);
      const curIndex = fieldToIndex.findIndex(m => m === curField);
      if (curIndex >= 0 && curIndex + 1 < fieldToIndex.length) {
        const nextField = fieldToIndex[curIndex + 1];
        this.displayField(nextField);
        return;
      }
    }
    this.displayFirstFieldToIndex();
  }

  private syncValues(documentFormFieldModel: IndexerDocumentFormFieldModel): Subscription {
    return documentFormFieldModel.fieldModel.synchronizeIndexerValue$().pipe(
      retry({
        count: 3,
        delay: 1000,
        resetOnSuccess: true
      }),
    ).subscribe({
      next: succeeded => {
        if (succeeded === false) {
          this.displayField(documentFormFieldModel);
        }
      },
      error: e => {
        this.errorService.handleError(e);
        const errorMessage = this.errorService.getAnyErrorMessage(e);
        console.warn(`Unable to sync value for field ${documentFormFieldModel.fieldModel.field.code}: ${errorMessage}`);
      }
    });
  }

}
